import "../Pages/PanelHome/PanelHome.css";
import { SignageBook } from "../Models/SignageBook";

type Props = {
    spotList?: SignageBook[];
    index: number;
    viewSala: Function;
};

const Spot: React.FC<Props> = ({ spotList, index, viewSala }) => {

    return (
        <img onClick={() => viewSala()} className='firstSpot' src={"data:image/png;base64," + spotList![index].file}></img>
    );
}

export default Spot;