declare global {
    interface Window {
        ROOT_DEV:string;
        ROOT_PROD:string;
    }
}

function isDevelopment() {
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
        return true
    } else {
        return false
    }
}

const BASE_DEV: string = window.ROOT_DEV;
const BASE_PROD: string = window.ROOT_PROD;


function getBaseUrl() {
    if (isDevelopment()) return BASE_DEV;
    else
    return BASE_PROD;
};

export class Endpoints {

    public static readonly Login = {
        get_PanelUserName: getBaseUrl()+ 'Setup/GetPanelManager/',
        post_NewAuthToken: getBaseUrl() + 'Login/Auth/',
        post_RegisterUser: getBaseUrl() + 'Login/Register',
        post_RefreshToken: getBaseUrl() + 'Login/extendtoken/',
        put_changePassword: getBaseUrl() + 'Login/changepassword/'
    }

    public static readonly ImpostazioniGen = {
        get_Get: getBaseUrl() + 'ImpostazioniGenerali/GetAllImpostazioni/',
        put_Update: getBaseUrl() + 'ImpostazioniGenerali/UpdateImpostazioni/',
        get_Get_Panel_Config: getBaseUrl() + 'ImpostazioniGenerali/GetPanelConfig/'
    }

    public static readonly Prenotazione = {

        post_CheckIn: getBaseUrl() + 'CheckIn/CheckIn/',
        post_CheckOut: getBaseUrl() + 'CheckIn/CheckOut/',
        post_Instant_Booking: getBaseUrl() + 'CheckIn/Instant/'

    }

    public static readonly Panel = {
        get_Get_Lista_Pannelli: getBaseUrl() + 'Pannello/ListaPannelli/',
        post_Get_Panel_RoomData: getBaseUrl() + 'Sala/GetPanelRoomData?idPannello='  ,
        post_Get_Panel_Signage: getBaseUrl() + 'Signage/GetBySala?idSala='
    }

}