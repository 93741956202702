
import { Button, FormControl, Grid, MenuItem, Select } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import "./PanelSelection.css";
import '../../Pages/Login/Login.css';
import PanelService from '../../Services/PanelService';
import { Pannello } from '../../Models/Pannello';
import { useNavigate } from 'react-router-dom';

function noSpace(e: React.KeyboardEvent) {
    let key = e.key;
    if (key === ' ') e.preventDefault();
}

/*impostazione overflow scroll in select*/
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const PanelSelection: React.FC = () => {

    const [listaPannelli, setListaPannelli] = useState<Pannello[]>([]);
    const [pannelloSelezionato, setPannelloSelezionato] = useState("");
    const [pannello, setPannello] = useState(0);
    let navigate = useNavigate();

    //carica listaPannelli all'avvio
    useEffect(() => { fetchPanelList(); }, []);

    useEffect(() => {
        
    }, [pannelloSelezionato]);


    async function fetchPanelList() {
        const res = await PanelService.ListaPannelli();
        setListaPannelli(res);
        
    }

    const handleChangePannello = (e: any) => {

        let input = (document.getElementById("inputPannello") as HTMLInputElement);
        input.valueAsNumber = e.target.value;
        setPannelloSelezionato(e.target.value);
    };

    function handleSubmit() {
        localStorage.setItem("idPannello", pannelloSelezionato);

        if (!pannelloSelezionato) {

        } else {
            navigate("../home", { replace: true });
        }
    }

    return (
        <>

            <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
                <Grid xs={12} sm={12} md={12} lg={12}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            /* handleSubmit(); */
                        }}
                        className='boxSxLogin'>

                        <div className='formContainer'>

                            {/*               <img className='teletab-logo' src={teletabberlogo}/>
 */}
                            {/*<div className='login_title'>{Dictionary.Login.loginFor_title}</div>}m*/}
                            <div className='login_title'>SELEZIONA IL PANNELLO DA GESTIRE</div>

                            {/*  <p className='login_subtitle'>{Dictionary.Login.loginForm_subtitle}</p>*/}

                            <FormControl variant="standard">
                                <FormControl className='formSelectPannelli' variant="standard" >
                                    <Select
                                        className='selectPanel'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={pannelloSelezionato}
                                        key={pannelloSelezionato}
                                        onChange={(e) => handleChangePannello(e)}
                                        color="success"
                                        label={'Seleziona un pannello'}
                                        MenuProps={MenuProps}
                                    >
                                        {/* error={ !pannello.nomeSala && !state.pannelloForm.nomeSala  && state.sala_blur && notValidSala}
                  onBlur={() => { handle_blur(azioni.BLUR_SALA) }}

                >*/}
                                        {listaPannelli.map((pannello: Pannello) =>
                                            <MenuItem key={pannello.idPannello} value={pannello.idPannello}>{pannello.nomeSala?.toLocaleUpperCase()} - {pannello.nome?.toLocaleUpperCase()}</MenuItem>

                                        )}
                                    </Select>
                                </FormControl>

                                <input type="number" id="inputPannello" /*defaultValue={ idSala }*/ readOnly hidden={true} />

                            </FormControl>

                            <Button
                                type='submit'
                                sx={{ m: 2, }}
                                className="buttonGo"
                                color={'success'}
                                variant="contained"
                                onClick={handleSubmit}>
                                VAI AL PANNELLO
                            </Button>

                            {/*       <LoadingBar width='5rem' show={loading} /> */}
                            <div className='err_accesso'>
                                {/*                 {state.loginfailed && <span>accesso non riuscito</span>}
 */}              </div>
                            <div className='bot-spacer'></div>
                        </div>
                    </form>
                </Grid>

            </Grid>
        </>
    )
}

export default React.memo(PanelSelection);