import { Box, Button } from "@mui/material";
import "../Components/Keypad.css";
import { faCircle, faDeleteLeft, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import DigitKey from "./DigitKey";
import PanelService from "../Services/PanelService";

type Props = {
    closeKeyPad: Function;
    accessCode?: string;
    idPrenotazione?: number;
    fetchRoomData: Function;
    checkType: string;
};

const Keypad: React.FC<Props> = ({ closeKeyPad, accessCode, idPrenotazione, fetchRoomData, checkType }) => {


    const [token, setToken] = useState("");
    const [showToken, setShowToken] = useState(false);
    const [boxDigitsClass, setBoxDigitsClass] = useState("boxDigits");
    const [wrongToken, setWrongToken] = useState(false);
    const [timer, setTimer] = useState(15000); //tempo di default da attendere prima dell'autoclose del pad

    async function handleSubmit(token: string) {

        reset();

        
        //verifica corrispondenza del token immesso

        if (token === accessCode) {  
           
            if(checkType==="In"){
            await PanelService.CheckIn(idPrenotazione!, token); 
            } else {
                await PanelService.checkOut(idPrenotazione!, token);
            }
            fetchRoomData();
            closeKeyPad();
           
        } else {
            setBoxDigitsClass("boxDigitsAnimated");
            setWrongToken(true);
            setToken("");
            setTimeout(() => { setBoxDigitsClass("boxDigits"); setWrongToken(false); }, 500);

        }
    }

    function handleShowToken() {

        reset();

        if (token)
            setShowToken(!showToken);
    }

    function addDigit(digit: number) {

        reset();

        if (token.length < 3)
            setToken(token + digit);
    }
    function deleteDigit() {

        reset();

        if (token.length > 0) {
            var lastDigit = token.length - 1;
            setToken(token.substring(0, lastDigit));
        }
    }

    useEffect(() => {

        const timerAutoClose = setInterval(tick, 1000);
        return function cleanup() {
            clearInterval(timerAutoClose);
        };
    }, [timer]);

    const tick = () => {

        if (timer === 0)
            closeKeyPad()
        else {
            setTimer(timer - 1000);
        }
    };


    const reset = () => setTimer(15000);

    return (
        <div className="bodyBackground" onClick={() => closeKeyPad()}>
            <Box className="boxKeypad" onClick={(e) => e.stopPropagation()}>
                <Box className={boxDigitsClass}>
                    {showToken === false || token.length === 0 ?
                        <>
                            <FontAwesomeIcon className={wrongToken && token.length >= 1 ? "fillDigitRed"
                                : wrongToken ? "firstDigitIconRed"
                                    : token.length >= 1 ? "fillDigit"
                                        : "firstDigitIcon"} icon={faCircle} />
                            <FontAwesomeIcon className={wrongToken && token.length >= 2 ? "fillDigitRed"
                                : wrongToken ? "secondDigitIconRed"
                                    : token.length >= 2 ? "fillDigit"
                                        : "secondDigitIcon"} icon={faCircle} />
                            <FontAwesomeIcon className={wrongToken && token.length >= 3 ? "fillDigitRed"
                                : wrongToken ? "thirdDigitIconRed"
                                    : token.length >= 3 ? "fillDigit"
                                        : "thirdDigitIcon"} icon={faCircle} />
                        </> :

                        <p className={wrongToken ? "tokenTextRed" : "tokenText"} hidden={!showToken}>{token}</p>
                    }
                </Box>
                <Box className="boxNumbers">
                    <Box className="OneTwoThree">
                        <DigitKey digit={1} addDigit={addDigit} />
                        <DigitKey digit={2} addDigit={addDigit} />
                        <DigitKey digit={3} addDigit={addDigit} /><br />
                    </Box>
                    <Box className="FourFiveSix">
                        <DigitKey digit={4} addDigit={addDigit} />
                        <DigitKey digit={5} addDigit={addDigit} />
                        <DigitKey digit={6} addDigit={addDigit} /><br />
                    </Box>
                    <Box className="SevenEightNine">
                        <DigitKey digit={7} addDigit={addDigit} />
                        <DigitKey digit={8} addDigit={addDigit} />
                        <DigitKey digit={9} addDigit={addDigit} /><br />
                    </Box>
                    <Box className="CancZeroEye">
                        <div className="specialDigits"  ><FontAwesomeIcon onClick={handleShowToken} icon={faEyeSlash} className="iconEye" /></div>
                        <DigitKey digit={0} addDigit={addDigit} />
                        <div className="specialDigits"  ><FontAwesomeIcon onClick={deleteDigit} icon={faDeleteLeft} className="iconDeleteLeft" /></div>
                    </Box>
                </Box>
                <Button className="btn_token" variant="contained" color="error"
                    onClick={() => handleSubmit(token)}>
                    Inserisci Token
                </Button>
                <Button className="btn_Key_back" variant="contained" color="error"
                    onClick={() => closeKeyPad()}>
                    Annulla
                </Button>
            </Box>



        </div>


    );
}

export default Keypad;
