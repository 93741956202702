import { Utente } from "../Models/Utente";
import AuthService from "./AuthService";
import { UserRoles } from "./Enums/UserRolesEnum";
import { LoginModel } from "./Reducers/LoginReducer";

export const TokenName: string = 'BsLiteSessionData';

export class AppSession {

    utente: Utente = new Utente();
    accessToken: string = ''; //stringa jwt criptata
    exp: Date = new Date(); //data/ora di scadenza sessione
}


export class SessionManager {


    //ottiene dati di sessione(JWT) tramite credenziali utente 
    //carica dati di sessione in LocalStorage
    public static async LoginWithCredentials(login: LoginModel): Promise<AppSession> {

        let token: string = await AuthService.GetAuthToken(login);
        let session: AppSession = new AppSession();

        if (token) session = AuthService.parseToken(token);

        SessionManager.StoreSession(session);
        
        return session;
    }


    //ottiene dati di sessione dal LocalStorage
    public static GetSessionFromStorage(): AppSession {
        let json = localStorage.getItem(TokenName);
    
        let session = new AppSession();

        if (json) {
            session = JSON.parse(localStorage.getItem(TokenName) || '');
            session.exp = new Date(session.exp);
        }
        return session;
    }

    public static StoreSession(session: AppSession) {

        localStorage.setItem(TokenName, JSON.stringify(session));
    }

    public static ClearSession() {
        localStorage.removeItem(TokenName);
    }

    //invia token jwt in scadenza al WebService per ricevere nuovo token con 30min di validità
    //carica i dati di sessione rinnovati in LocalStorage(a disposizione del sessionContext);
    public static async RefreshSession() {
        let session = this.GetSessionFromStorage();
        
        if (session && session.exp /*&& this.isExpiring(session)*/) {
           
            let newSession: AppSession = new AppSession();

            let newToken = await AuthService.GetExtendedToken();

            if (newToken) newSession = AuthService.parseToken(newToken);
            if (this.isValid(newSession)) {
                
                this.StoreSession(newSession);
                return newSession;

            } else {return session; }
        }
    }

    public static async ForceRefreshSession() {

        let session = this.GetSessionFromStorage();

        let newSession: AppSession = new AppSession();

        let newToken = await AuthService.GetExtendedToken();

        if (newToken) newSession = AuthService.parseToken(newToken);

        if (this.isValid(newSession)) {

            this.StoreSession(newSession);
            
            return newSession;

        } else { return session; }
    }

    // TRUE se utente loggato è ADMIN e sessione ancora valida
    public static LoggedAsAdmin(session: AppSession): boolean {

        return this.isValid(session) && session.utente.role === UserRoles.ADMIN;
    }

    //--------------TIME OPERATIONS----------------

    //TRUE se la sessione non è ancora scaduta
    public static isValid(session: AppSession): boolean {
        return session.exp.getTime() > new Date().getTime();
    }

    //TRUE se il token ha meno di 20 min di validità residua
    public static isExpiring(session: AppSession): boolean {

        if (session == null) return false;
        let timein20mins = this.addMinutes(new Date(), 20);
        return (this.isAfter(timein20mins, (session.exp as Date)));
    }

    //restituisce oggetto Date incrementato del n.di minuti indicati
    public static addMinutes(date: Date, minutes: number): Date {
        return new Date(date.getTime() + minutes * 60000);
    }

    //TRUE se date1 è DOPO date2
    public static isAfter(date1: Date, date2: Date): boolean {
        return date1.getTime() >= date2.getTime();
    }

    //--------------TIME OPERATIONS-------------------

}

