import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import PanelHome from './Pages/PanelHome/PanelHome';
import { HashRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './Pages/Login/Login';
import PanelSelection from './Pages/PanelSelection/PanelSelection';
import { AppSession, SessionManager } from './Services/SessionManager';
import { SessionContext } from './Context/SessionContext';
import NotFound from './Pages/Login/NotFound';
import OutOfConnection from './Pages/Login/OutOfConnection';

function App() {

  const [session, setSession] = useState(new AppSession());
  const [content, setContent] = useState(<></>);
  const [sessionLoaded, setSessionLoaded] = useState(false);

  const navigate = useNavigate();

  const setRedirect = () => {
    let content = (!SessionManager.isValid(session)) ? <Login /> : <NotFound />
    return content;
  }

  useEffect(() => { //all'avvio, carica token di sessione da localstorage
    if (!SessionManager.isValid(session))
      setSession(SessionManager.GetSessionFromStorage());
    setSessionLoaded(true);
  }, []);

  useEffect(() => { //monitoraggio e validità di sessione
    const interval = setInterval(() => {
      setSession(SessionManager.GetSessionFromStorage());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => { //redirection su login quando sessione scade

    if (sessionLoaded && !SessionManager.isValid(session)) {
      navigate("/", { replace: false });
    }
    setContent(setRedirect());
  }, [session]);

  return (
    <div className="App">
      <SessionContext.Provider value={{ session, setSession }}>

        <Routes>

          <Route path="/" element={<Login />} />
          <Route path="/outOfConnection" element={<OutOfConnection isConnected={true}/>} />
          {SessionManager.isValid(session) && <>
            <Route path="/panel" element={<PanelSelection />} />
            <Route path="home" element={<PanelHome />} />

          </>
          }
          <Route path="/" element={<Login />} />
          <Route path="*" element={content} />
        </Routes>
      </SessionContext.Provider>

    </div >
  );
}

export default App;


