
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingBar from "../../Layouts/LoadingBar";

type Props = {
    isConnected: boolean;
};

const OutOfConnection: React.FC<Props> = ({ isConnected }) => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [goShow, setGoShow] = useState(false);


    useEffect(() => { setTimeout(() => { setGoShow(true) }, 500) }, []);

    const handleReconnection = () => {
        setLoading(true);
        setTimeout(() => {
            navigate('/');
            setLoading(false);
        }, 3500);
    }

    useEffect(() => {
        const connectionTimer = setInterval(handleReconnection, 10000);

        return function stopTimer() {
            clearInterval(connectionTimer);
        }
    }, []);

    if (!isConnected && goShow) {
        return (

            <>
                <div className='bot-spacer'></div>
                <div className='bot-spacer'></div>
                <div className='bot-spacer'></div>
                <div className='bot-spacer'></div>
                {!loading && <Button
                    onClick={() => handleReconnection()}
                    className="btnpad buttonLogin" color={'success'} variant="contained">
                    Riconnetti manualmente
                </Button>}
                <div className={loading ? '' : 'err_accesso'} style={{ marginTop: '2rem', }}>{loading ? 'Tentativo di riconnessione in corso..' : 'Connessione assente. Il sistema tenterà di riconnettersi a breve.'}</div>
                <br />
                <LoadingBar width='14.5rem' marginLeft='32.7rem' show={loading} />
                <div style={{ marginLeft: '0.6rem' }} className='err_accesso'>
                </div>
                <div className='bot-spacer'></div>
            </>

        )
    } else {
        return <></>
    }
}

export default OutOfConnection;