import { Box, Typography, Paper, Grid, Divider, Button, } from '@mui/material';
import "../Pages/PanelHome/PanelHome.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarDays, faSquareCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import { Prenotazione } from '../Models/Prenotazione';
import { Colors, LedService } from '../Models/LedService';
import { SignageBook } from '../Models/SignageBook';
import Spot from './Spot';
import { StatoSala } from '../Services/Enums/StatoSala';

type Props = {
    _statoSala: string;
    titoloMeeting?: string;
    nextEvent?: string;
    inizioMeeting?: string;
    fineMeeting?: string;
    referenteMeeting?: string;
    timerSlideshow?: number;
    nextRoomEvents?: Array<Prenotazione>;
    logoSxPath: any;
    logoDxPath: any;
    nomeSala: string;
    fetchRoomData: Function;
    pollingTimer: number;
    instantBooking: boolean;
    checkInTimer: number;
    openKeyPad: Function;
    openTimePad: Function;
    stopSlideShow: boolean;
    patternSlides: string[];
    spotList: SignageBook[];
    resetIndex: boolean;
    tElenco: number;
    tSpot: number;
    tStato: number;
    token: string | null;
    instantConfig: boolean;
    timefromEventStart: number;
    fetchTimeforCheckIn: Function;
    secondsfromEventStart: number;
    checkSala: Function;
    endOfEvents: boolean;
    checkInEnabled: boolean;
    uniqueId?: string;
    checkedIn?: boolean;
};

const BoardPanel: React.FC<Props> = ({ _statoSala, titoloMeeting, nextEvent, inizioMeeting, fineMeeting,
    referenteMeeting, timerSlideshow, nextRoomEvents, logoSxPath, logoDxPath, nomeSala, fetchRoomData,
    pollingTimer, instantBooking, checkInTimer, openKeyPad, openTimePad, stopSlideShow, patternSlides, spotList, resetIndex,
    tElenco, tSpot, tStato, token, instantConfig, timefromEventStart, fetchTimeforCheckIn, secondsfromEventStart, checkSala, endOfEvents, checkInEnabled, uniqueId, checkedIn }) => {

    //forzo visualizzazione stato sala libera per ottimizzare UX, aspettando che il servizio backend vada a liberare la sala in database
    if(_statoSala === StatoSala.occupata && (inizioMeeting==undefined || fineMeeting==undefined)) _statoSala = StatoSala.libera;
    //forzo visualizzazione stato sala riservata per ottimizzare UX, aspettando che il servizio backend vada a riservare la sala in database
    //if(_statoSala === StatoSala.libera && (uniqueId && uniqueId!= "Instant Booking")) _statoSala = StatoSala.riservata;
    
    
    

    const oraData = function fetchData(data: Date) {
        const today = data;
        const _oraData: string = (today.getDate()< 10 ? "0" + (today.getDate()) : (today.getDate())) + '/' + ((today.getMonth() + 1)< 10? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '/' + today.getFullYear();
        return _oraData;
    }
    const [time, setTime] = useState(new Date());
    const tomorrow = new Date(time);
    tomorrow.setDate(tomorrow.getDate() + 1);

    interface ICountdown {
        minutes: number;
        seconds: number;
    }

    const [statoSalaOld, setStatoSalaOld] = useState("");
    const [timeCd, setTimeCd] = useState<ICountdown>({ minutes: checkInTimer - timefromEventStart, seconds: secondsfromEventStart }); //qui il passo impostato da cms indicherà la quantità di minuti per il timer, lo  come prop
    const [countDown, setCountdown] = useState(false);
    const [index, setIndex] = useState(0);
    const [checkNext, setCheckNext] = useState(false);
    const [_checkSala, setCheckSala] = useState(false);

   
    useEffect(() => { setTimeCd({ minutes: checkInTimer - timefromEventStart, seconds: secondsfromEventStart }) }, [timefromEventStart]);

    fetchTimeforCheckIn();

    const tipoSlide = stopSlideShow === true ? patternSlides[0] : patternSlides[index];


    function refreshClock() {
        setTime(new Date());
    }

    useEffect(() => {
        const timerData = setInterval(fetchRoomData, pollingTimer);

        return function cleanup() {
            clearInterval(timerData);
        };
    }, [nomeSala]);

    useEffect(() => {
        const timerId = setInterval(refreshClock, 1000);
        if (countDown) {
            const timerCd = setInterval(tick, 1000);
            return function cleanup() {
                clearInterval(timerId);
                clearInterval(timerCd);
            };
        } else {
            return function cleanup() {
                clearInterval(timerId);
            };
        }
    }, [countDown, timeCd]);


    useEffect(() => {

        if (tipoSlide === "Sala") {
            timerSlideshow = tStato >= 1000 ? tStato : 5000;
        } else if (tipoSlide === "NextEvents") {
            if (nextRoomEvents?.length == 0) { timerSlideshow = 0; }
            else {
                timerSlideshow = tElenco >= 1000 ? tElenco : 5000;
            }
        } else if (tipoSlide === "Spot") {
            timerSlideshow = tSpot >= 1000 ? tSpot : 5000;
        } else {
            timerSlideshow = 5000;
        }

        setTimeout(() =>
            setIndex((prevIndex) =>
                prevIndex === patternSlides.length - 1 ? 0 : prevIndex + 1

            ), timerSlideshow
        );

        return () => { };
    }, [index]);

    useEffect(() => {

        if ((_statoSala === StatoSala.riservata)) {
            
            setStatoSalaOld(_statoSala);
            setCountdown(true);
        } else {
            
        }
        if (_statoSala === StatoSala.riservata) {
            LedService.setLed(Colors.YELLOW)
        } else if (_statoSala === StatoSala.occupata) {
            LedService.setLed(Colors.RED)
        }
        else if (_statoSala == StatoSala.libera) {
            LedService.setLed(Colors.GREEN)
        }
        else {
            LedService.setLed(Colors.OFF)
        }

    }, [_statoSala]);

    function padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }

    function formatTime(date: Date) {
        return (
            [
                padTo2Digits(date.getHours()),
                padTo2Digits(date.getMinutes())
            ].join(':')
        );
    }

    const tick = () => {



        if (timeCd.minutes <= 0 && timeCd.seconds === 0) {
            _statoSala = StatoSala.libera;
            if (titoloMeeting != "") fetchRoomData();
            reset();
        }
        else if (timeCd.seconds === 0) {
            setTimeCd({ minutes: timeCd.minutes - 1, seconds: 59 })
        }
        else {
            setTimeCd({ minutes: timeCd.minutes, seconds: timeCd.seconds - 1 });
        }
    };


    const reset = () => setTimeCd({ minutes: timeCd.minutes, seconds: timeCd.seconds });

    const convertBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    function checkNextEvents() {
        setCheckNext(true);
        

        setTimeout(() => 
            setCheckNext(false), tElenco
           
        );
    }

    function checkStatoSala() {
        setCheckSala(true);
        

        setTimeout(() => 
        setCheckSala(false), tStato
           
        );
    }

    if(_statoSala === StatoSala.riservata && (checkedIn == false && timeCd.minutes < 0)) _statoSala = StatoSala.libera;


    if ((tipoSlide == "Sala" && checkNext == false) || (_checkSala == true && checkNext == false)) {
        return (
            <div className='wrapper'>
                <Box

                    component="main"
                    className={_statoSala === StatoSala.libera ? 'body_salaLibera' : _statoSala === StatoSala.riservata ? "body_salaRiservata" : _statoSala === StatoSala.occupata ? "body_salaOccupata" : ""}
                    sx={{
                        opacity: "0.9"
                    }}
                >

                    <Grid className="insideSala" container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={{ xs: 2, md: 0.5 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                        <Grid item xs={12} sm={12} md={12}>
                            <Paper className='header' elevation={0}>
                                <Box className='box_ora_data'>
                                    <Box component="h1" className="text_Ora">{formatTime(new Date())}</Box>
                                    <Box component="h1" className="text_Data">{oraData(time)}</Box>
                                </Box>
                                <Box component="h1" className={ nomeSala && (nomeSala.length > 50 ? "text_nome_Sala_micro" : nomeSala.length > 24 ? "text_nome_Sala_mini" : "text_nome_Sala") }>
                                    {nomeSala && nomeSala.toLocaleUpperCase()}
                                </Box>
                                <Box component="h1" className="box_calendar">
                                    <FontAwesomeIcon onClick={() => { checkNextEvents() }} className='iconCalendar' icon={faCalendarDays} />
                                </Box>

                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Typography className={_statoSala == StatoSala.libera ? 'mainBoxLibera' : _statoSala == StatoSala.occupata?"mainBoxOccupata" : _statoSala==StatoSala.riservata?"mainBoxRiservata" : "" } paragraph>
                                {_statoSala !== StatoSala.libera && <div className="referenteMeeting">
                                    <Box component="h2" className="referente">{_statoSala == StatoSala.libera ? "" : (_statoSala === StatoSala.riservata || _statoSala === StatoSala.occupata) ? referenteMeeting?.toLocaleUpperCase() : "SALA DISPONIBILE"}</Box>
                                </div>}
                                {_statoSala !== StatoSala.libera && <Divider className='divider'></Divider>}
                                <div style={{color: "whitesmoke"}} className={_statoSala===StatoSala.libera?"home_sala_status_Libera":"home_sala_status_Riservata"}>

                                    <Box sx={_statoSala == StatoSala.libera ? {} : (titoloMeeting && titoloMeeting.length > 45) ? { paddingTop: "1.1rem" } : {}}

                                        className={_statoSala === StatoSala.libera ? "nome_sala_libera" :
                                            (titoloMeeting && titoloMeeting.length > 80) ? "status_sala_micro" : 
                                            (titoloMeeting && titoloMeeting.length > 30) ? "status_sala_mini" : 
                                            "status_sala"}>

                                        {_statoSala === StatoSala.libera ? "SALA DISPONIBILE" : (_statoSala === StatoSala.riservata || _statoSala == StatoSala.occupata) ? titoloMeeting?.toLocaleUpperCase() : "SALA DISPONIBILE"}
                                    </Box>

                                </div>
                                {_statoSala !== StatoSala.libera && <Divider className="divider"></Divider>}
                                <div className='orarioMeeting' >
                                    {(_statoSala === StatoSala.riservata || _statoSala === StatoSala.occupata && inizioMeeting!=undefined && fineMeeting!=undefined) && <FontAwesomeIcon className='iconClock' icon={faClock} />}
                                    <Box component="h2" className='home_sala_orario_Meeting'>{(_statoSala === StatoSala.riservata || _statoSala == StatoSala.occupata&& inizioMeeting!=undefined && fineMeeting!=undefined) ? inizioMeeting?.substring(11, 16)
                                        + " - "
                                        + fineMeeting?.substring(11, 16)
                                        : ""}</Box>
                                </div>

                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} className={(_statoSala === StatoSala.occupata) ? "tallmargin" : "lowmargin"}>
                            <Grid className="grid_nextEvents" item xs={12} sm={12} md={12}>
                                <Typography paragraph>

                                    <Paper className="box_next_events" elevation={0}>
                                        <Box component="h2" className="text_next_events">{nextEvent ? "PROSSIMA: " + (nextEvent.length < 76 ? nextEvent.toLocaleUpperCase() : nextEvent.toLocaleUpperCase().substring(0, 76) + "...")
                                            + " "
                                            + oraData(new Date(nextRoomEvents![0].dataInizio!))
                                            + " "
                                            + nextRoomEvents![0].dataInizio?.toString().substring(11, 16) + " - "
                                            + nextRoomEvents![0].dataFine?.toString().substring(11, 16) : "NESSUN EVENTO IN PROGRAMMA NELLE ORE SUCCESSIVE"}
                                        </Box>
                                    </Paper>
                                </Typography>
                            </Grid>
                            <Grid className='grid_IB' item xs={12} sm={12} md={12}>
                                <Typography paragraph>
                                    <Paper className="box_instant_booking" elevation={0}>
                                        <div className={logoSxPath ? "countdown_withLogos" : "countdownOnlyDxLogo"}>

                                            <div className='BoxLogoSx'>
                                                {logoSxPath && <img src={`../Images/${logoSxPath}`} className={logoSxPath ? "LogoSx" : ""} />}
                                            </div>
                                            {/*=====================================================================================================================================================*/}
                                            <div className='button-countdown-container'>

                                                {(_statoSala === StatoSala.riservata && ((timeCd.minutes != 0 && timeCd.minutes >= 0) || timeCd.seconds != 0 && timeCd.seconds >=0)) &&
                                                    <Box className="countdown">
                                                        {`${timeCd.minutes.toString().padStart(2, '0')}:${timeCd.seconds.toString().padStart(2, '0')}`} RIMANENTI
                                                    </Box>}
                                                {(instantConfig && instantBooking && _statoSala === StatoSala.libera) && <Button className={(_statoSala === StatoSala.libera || (timeCd.minutes == 0 && timeCd.seconds == 0)) ?
                                                    "btnIB" : "btnConfermaPrenotazione"} variant="contained" color="error" onClick={() => openTimePad()}>
                                                    Instant Booking

                                                </Button>}{(_statoSala === StatoSala.riservata && (timeCd.minutes != 0 || timeCd.seconds != 0)) &&
                                                    <Button className={(timeCd.minutes <= 0 && timeCd.seconds === 0) ? "btnIB" : "btnConfermaPrenotazione"}
                                                        variant="contained" color="error"
                                                        onClick={() => openKeyPad("In")}>
                                                        Conferma Prenotazione
                                                    </Button>}
                                                {(_statoSala === StatoSala.occupata && token != null && checkInEnabled === true) &&
                                                    <Button className={(timeCd.minutes == 0 && timeCd.seconds == 0) ? "btnIB" : "btnFineMeeting"}
                                                        variant="contained" color="error"
                                                        onClick={() => openKeyPad("Out")}>
                                                        Fine Meeting
                                                    </Button>}

                                            </div>

                                            <div className='BoxLogoDx'>
                                                {logoDxPath &&
                                                    <img src={`../Images/${logoDxPath}`} className="newLogoDx" />}
                                            </div>
                                        </div>


                                    </Paper>
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </div>

        )
    } else if (checkNext == true || (tipoSlide == "NextEvents" && endOfEvents == false)) {
        return (

            <Box

                component="main"
                className={'body_nextEvents'}
                sx={{
                    flexGrow: 1, p: 2, opacity: "0.9"
                }}
            >


                <Grid container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={{ xs: 2, md: 0.5 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                    <Grid item xs={12} sm={12} md={12}>
                        <Paper className='header' elevation={0}>
                            <Box className='box_ora_data'>
                                <Box component="h1" className="text_Ora">{formatTime(new Date())}</Box>
                                <Box component="h1" className="text_Data">{oraData(time)}</Box>
                            </Box>
                            <Box component="h1" className={nomeSala && (nomeSala.length > 50 ? "text_nome_Sala_micro" : nomeSala.length > 24 ? "text_nome_Sala_mini" : "text_nome_Sala")}>
                                {nomeSala && nomeSala.toLocaleUpperCase()}
                            </Box>

                            <Box component="h1" className="box_calendar">
                                <FontAwesomeIcon onClick={() => { if (checkNext===true) {setCheckNext(false); checkStatoSala();}else{checkStatoSala();}  }} className='iconCalendar' icon={faSquareCaretLeft} /> 
                            </Box>

                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Typography className='mainEventBox' >

                            {nextRoomEvents?.slice(0, 9).map((prenotazione: Prenotazione) => <> <Box className='rowNextMeetings'>
                                <div>
                                    <Box sx={{ marginRight: "1.5rem" }} className='dataNextMeeting'>{oraData(new Date(prenotazione.dataInizio!))}</Box>
                                </div>
                                <div className="dataNextMeeting">

                                    <Box className="">{prenotazione.dataInizio?.toString().substring(11, 16) as unknown as string
                                        + " - " +
                                        prenotazione.dataFine?.toString().substring(11, 16) as unknown as string}</Box>
                                </div>
                                <div className="titoloNextMeeting">
                                    <Box className="" >
                                        {(prenotazione.titolo!.length < 60 && prenotazione.titolo)
                                            ? `${prenotazione.titolo?.toLocaleUpperCase()}`
                                            : `${prenotazione.titolo?.toLocaleUpperCase().substring(0, 60)}...`}</Box>
                                </div>
                                <div className="referenteNextMeeting">
                                    {prenotazione.nomeOrganizzatore && <Box className=""> {prenotazione.nomeOrganizzatore != null ? (prenotazione.nomeOrganizzatore!.length < 40 && prenotazione.nomeOrganizzatore)
                                        ? `${prenotazione.nomeOrganizzatore?.toLocaleUpperCase()}`
                                        : `${prenotazione.nomeOrganizzatore?.toLocaleUpperCase().substring(0, 40)}...` : "----"}</Box>}

                                </div>


                            </Box>
                                <Divider className="dividerMeetingTable"></Divider>
                            </>)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "12.45rem" }}>
                        <Grid className="grid_nextEvents_Elenco" item xs={12} sm={12} md={12}>
                            <Typography paragraph>

                                <Paper className="box_next_events_Elenco" elevation={0}>
                                    <Box component="h2" className="text_next_events">{nextEvent ? "PROSSIMA: " + (nextEvent.length < 76 ? nextEvent.toLocaleUpperCase() : nextEvent.toLocaleUpperCase().substring(0, 76) + "...")
                                        + " "
                                        + oraData(new Date(nextRoomEvents![0].dataInizio!))
                                        + " "
                                        + nextRoomEvents![0].dataInizio?.toString().substring(11, 16) + " - "
                                        + nextRoomEvents![0].dataFine?.toString().substring(11, 16) : "NESSUN EVENTO IN PROGRAMMA NELLE ORE SUCCESSIVE"}
                                    </Box>
                                </Paper>
                            </Typography>
                        </Grid>
                        <Grid className='grid_IB' item xs={12} sm={12} md={12}>
                            <Typography paragraph>
                                <Paper className="box_instant_booking" elevation={0}>

                                    <Paper className="box_instant_booking" elevation={0}>
                                        <Box component="h2" className={logoSxPath ? "countdown_withLogos" : "countdownOnlyDxLogo"}>

                                            <div className='BoxLogoSx'>
                                                {logoSxPath && <img src={`../Images/${logoSxPath}`} className={logoSxPath ? "LogoSx" : ""} />}
                                            </div>

                                            <div className='button-countdown-container'>

                                                {(_statoSala === StatoSala.riservata && (timeCd.minutes != 0 || timeCd.seconds != 0)) &&
                                                    <Box className="countdown">
                                                        {`${timeCd.minutes.toString().padStart(2, '0')}:${timeCd.seconds.toString().padStart(2, '0')}`} RIMANENTI
                                                    </Box>
                                                }

                                                {(instantConfig && instantBooking && _statoSala === StatoSala.libera) && <Button className={(_statoSala === StatoSala.libera || (timeCd.minutes == 0 && timeCd.seconds == 0)) ?
                                                    "btnIB" : "btnConfermaPrenotazione"} variant="contained" color="error" onClick={() => openTimePad()}>
                                                    Instant Booking
                                                </Button>}{(_statoSala === StatoSala.riservata && (timeCd.minutes != 0 || timeCd.seconds != 0)) &&
                                                    <Button className={(timeCd.minutes <= 0 && timeCd.seconds === 0) ? "btnIB" : "btnConfermaPrenotazione"}
                                                        variant="contained" color="error"
                                                        onClick={() => openKeyPad("In")}>
                                                        Conferma Prenotazione
                                                    </Button>}
                                                {(_statoSala === StatoSala.occupata && token != null && checkInEnabled === true) &&
                                                    <Button id="buttonEnd" className={(timeCd.minutes == 0 && timeCd.seconds == 0) ? "btnIB" : "btnFineMeeting"}
                                                        variant="contained" color="error"
                                                        onClick={() => openKeyPad("Out")}>
                                                        Fine Meeting
                                                    </Button>}

                                            </div>

                                            <div className="BoxLogoDx">
                                                {logoDxPath && <img src={`../Images/${logoDxPath}`}
                                                    className="newLogoDx" />}
                                            </div>

                                        </Box>


                                    </Paper>



                                </Paper>
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>
            </Box>

        )
    } else if (tipoSlide === "Spot") {
        return (<Spot viewSala={checkStatoSala} spotList={spotList} index={((index + 1) / 3 - 1)}></Spot>)
    } else {
        return (<div className='wrapper'>
        <Box

            component="main"
            className={_statoSala === StatoSala.libera ? 'body_salaLibera' : _statoSala === StatoSala.riservata ? "body_salaRiservata" : _statoSala === StatoSala.occupata ? "body_salaOccupata" : ""}
            sx={{
                opacity: "0.9"
            }}
        >

            <Grid className="insideSala" container rowSpacing={0.5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} spacing={{ xs: 2, md: 0.5 }} columns={{ xs: 12, sm: 12, md: 12 }}>

                <Grid item xs={12} sm={12} md={12}>
                    <Paper className='header' elevation={0}>
                    <Box className='box_ora_data'>
                                    <Box component="h1" className="text_Ora">{formatTime(new Date())}</Box>
                                    <Box component="h1" className="text_Data">{oraData(time)}</Box>
                                </Box>
                        <Box component="h1" className={ nomeSala && (nomeSala.length > 50 ? "text_nome_Sala_micro" : nomeSala.length > 24 ? "text_nome_Sala_mini" : "text_nome_Sala") }>
                            {nomeSala && nomeSala.toLocaleUpperCase()}
                        </Box>
                        <Box component="h1" className="box_calendar">
                            <FontAwesomeIcon onClick={() => { checkNextEvents() }} className='iconCalendar' icon={faCalendarDays} />
                        </Box>

                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                    <Typography className={_statoSala == StatoSala.libera ? 'mainBoxLibera' : _statoSala == StatoSala.occupata?"mainBoxOccupata" : _statoSala==StatoSala.riservata?"mainBoxRiservata" : "" } paragraph>
                        {_statoSala !== StatoSala.libera && <div className="referenteMeeting">
                            <Box component="h2" className="referente">{_statoSala == StatoSala.libera ? "" : (_statoSala === StatoSala.riservata || _statoSala === StatoSala.occupata) ? referenteMeeting?.toLocaleUpperCase() : "SALA DISPONIBILE"}</Box>
                        </div>}
                        {_statoSala !== StatoSala.libera && <Divider className='divider'></Divider>}
                        <div style={{color: "whitesmoke"}} className={_statoSala===StatoSala.libera?"home_sala_status_Libera":"home_sala_status_Riservata"}>

                            <Box sx={_statoSala == StatoSala.libera ? {} : (titoloMeeting && titoloMeeting.length > 45) ? { paddingTop: "1.1rem" } : {}}

                                className={_statoSala === StatoSala.libera ? "nome_sala_libera" :
                                    (titoloMeeting && titoloMeeting.length > 80) ? "status_sala_micro" : 
                                    (titoloMeeting && titoloMeeting.length > 30) ? "status_sala_mini" : 
                                    "status_sala"}>

                                {_statoSala === StatoSala.libera ? "SALA DISPONIBILE" : (_statoSala === StatoSala.riservata || _statoSala == StatoSala.occupata) ? titoloMeeting?.toLocaleUpperCase() : "SALA DISPONIBILE"}
                            </Box>

                        </div>
                        {_statoSala !== StatoSala.libera && <Divider className="divider"></Divider>}
                        <div className='orarioMeeting' >
                            {(_statoSala === StatoSala.riservata || _statoSala === StatoSala.occupata) && <FontAwesomeIcon className='iconClock' icon={faClock} />}
                            <Box component="h2" className='home_sala_orario_Meeting'>{(_statoSala === StatoSala.riservata || _statoSala == StatoSala.occupata) ? inizioMeeting?.substring(11, 16)
                                + " - "
                                + fineMeeting?.substring(11, 16)
                                : ""}</Box>
                        </div>

                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} className={(_statoSala === StatoSala.occupata) ? "tallmargin" : "lowmargin"}>
                    <Grid className="grid_nextEvents" item xs={12} sm={12} md={12}>
                        <Typography paragraph>

                            <Paper className="box_next_events" elevation={0}>
                                <Box component="h2" className="text_next_events">{nextEvent ? "PROSSIMA: " + (nextEvent.length < 76 ? nextEvent.toLocaleUpperCase() : nextEvent.toLocaleUpperCase().substring(0, 76) + "...")
                                    + " "
                                    + oraData(new Date(nextRoomEvents![0].dataInizio!))
                                    + " "
                                    + nextRoomEvents![0].dataInizio?.toString().substring(11, 16) + " - "
                                    + nextRoomEvents![0].dataFine?.toString().substring(11, 16) : "NESSUN EVENTO IN PROGRAMMA NELLE ORE SUCCESSIVE"}
                                </Box>
                            </Paper>
                        </Typography>
                    </Grid>
                    <Grid className='grid_IB' item xs={12} sm={12} md={12}>
                        <Typography paragraph>
                            <Paper className="box_instant_booking" elevation={0}>
                                <div className={logoSxPath ? "countdown_withLogos" : "countdownOnlyDxLogo"}>

                                    <div className='BoxLogoSx'>
                                        {logoSxPath && <img src={`../Images/${logoSxPath}`} className={logoSxPath ? "LogoSx" : ""} />}
                                    </div>
                                    {/*=====================================================================================================================================================*/}
                                    <div className='button-countdown-container'>

                                        {(_statoSala === StatoSala.riservata && (timeCd.minutes != 0 || timeCd.seconds != 0)) &&
                                            <Box className="countdown">
                                                {`${timeCd.minutes.toString().padStart(2, '0')}:${timeCd.seconds.toString().padStart(2, '0')}`} RIMANENTI
                                            </Box>}
                                        {(instantConfig && instantBooking && _statoSala === StatoSala.libera) && <Button className={(_statoSala === StatoSala.libera || (timeCd.minutes == 0 && timeCd.seconds == 0)) ?
                                            "btnIB" : "btnConfermaPrenotazione"} variant="contained" color="error" onClick={() => openTimePad()}>
                                            Instant Booking

                                        </Button>}{(_statoSala === StatoSala.riservata && (timeCd.minutes != 0 || timeCd.seconds != 0)) &&
                                            <Button className={(timeCd.minutes <= 0 && timeCd.seconds === 0) ? "btnIB" : "btnConfermaPrenotazione"}
                                                variant="contained" color="error"
                                                onClick={() => openKeyPad("In")}>
                                                Conferma Prenotazione
                                            </Button>}
                                        {(_statoSala === StatoSala.occupata && token != null && checkInEnabled === true) &&
                                            <Button className={(timeCd.minutes == 0 && timeCd.seconds == 0) ? "btnIB" : "btnFineMeeting"}
                                                variant="contained" color="error"
                                                onClick={() => openKeyPad("Out")}>
                                                Fine Meeting
                                            </Button>}

                                    </div>

                                    <div className='BoxLogoDx'>
                                        {logoDxPath &&
                                            <img src={`../Images/${logoDxPath}`} className="newLogoDx" />}
                                    </div>
                                </div>


                            </Paper>
                        </Typography>

                    </Grid>
                </Grid>
            </Grid>
        </Box>
    </div>)
    }
}
export default BoardPanel;
