import axios from "axios"
import jwtDecode from "jwt-decode";
import { Endpoints } from "../Config/Endpoints";
import { LoginModel } from "./Reducers/LoginReducer";
import { AppSession } from "./SessionManager";
import { RequestConfig } from "./Utils/RequestConfig";


export default class AuthService {

    public static async GetPanelUser(){
        return await axios.get(Endpoints.Login.get_PanelUserName, RequestConfig.queryparams())
            .then(res => {
                
                return res.data;

            }, (error) => { return ''; });
    }

    public static async GetAuthToken(login: LoginModel): Promise<string> {
        let Credentials = {
            email: login.email,
            password: login.password.Value()
        }

        return await axios.post(Endpoints.Login.post_NewAuthToken, Credentials, RequestConfig.queryparams())
            .then(res => {

                return res.data;

            }, (error) => { return ''; });
    }

    public static parseToken(accessToken: string): AppSession {

        let decoded: any = jwtDecode(accessToken);

        let session: AppSession = {
            utente: {
                id: decoded.id,
                userName: decoded.userName,
                role: decoded.roles,
            },
            accessToken: accessToken,
            exp: this.dateFromJWTmillis(decoded.exp)
        }
        return session;
    }

    public static async GetExtendedToken(): Promise<string> {

        return await axios.post(Endpoints.Login.post_RefreshToken,{},RequestConfig.queryparams())
            .then(res => {

                return res.data as string;

            }, (error) => {
                return '';
            });

    }

    public static dateFromJWTmillis(millis: number) {
        return new Date(millis * 1000);
    }
}