export class CheckIn {
    IdPrenotazione? : number;
    AccessCode?: string;
    IdBadge?:string;
    

    constructor(IdPrenotazione?: number, AccessCode?: string) {

        this.IdPrenotazione = IdPrenotazione;
        this.AccessCode = AccessCode;
    }

    public static costruttoreEsteso(
        IdPrenotazione: number,
        AccessCode: string,
       ) {

        let checkIn = new CheckIn();

        if (IdPrenotazione) checkIn.IdPrenotazione = IdPrenotazione;
        if (AccessCode) checkIn.AccessCode = AccessCode;

        return checkIn;
    } 

}