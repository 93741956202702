
import { Button, FormControl, Grid, Input, InputLabel } from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dictionary from '../../Config/dictionary';
import { useSession } from '../../Context/SessionContext';
import LoadingBar from '../../Layouts/LoadingBar';
import '../../Pages/Login/Login.css';
import AuthService from '../../Services/AuthService';
import PanelService from '../../Services/PanelService';
import { GetLoginModel, LoginActions, LoginModel, LoginReducer, LoginState } from '../../Services/Reducers/LoginReducer';
import { AppSession, SessionManager } from '../../Services/SessionManager';

function noSpace(e: React.KeyboardEvent) {
  let key = e.key;
  if (key === ' ') e.preventDefault();
}

const Login: React.FC = () => {

  const [state, dispatch] = useReducer(LoginReducer, new LoginState());
  const [loading, setLoading] = useState(false);
  const { session, setSession } = useSession();
  const navigate = useNavigate();

  useEffect(() => { 
    setSession(SessionManager.GetSessionFromStorage());
    loadPanelUser();
  }, []);

  async function loadPanelUser(){
    let model = await GetLoginModel();
    dispatch({ type: LoginActions.LOAD_PANELUSER, value: model });
  }

  useEffect(() => {
    if (SessionManager.isValid(session)) {
      let idPannello = localStorage.getItem("idPannello");
      if (idPannello) {
        navigate(dictionary.Routes.landingPage_home, { replace: true });
      } else {
        navigate(dictionary.Routes.landingPage_panel, { replace: true });
      }
    }
  }, [session]);

  async function handleSubmit() {
    setLoading(true);

    if (state.passwordValid) {

      let newSession: AppSession = await SessionManager.LoginWithCredentials(state.loginModel);

      if (SessionManager.isValid(newSession)) {

        setSession(newSession);

      } else dispatch({ type: LoginActions.LOGIN_FAIL });

    } else { dispatch({ type: LoginActions.SUBMIT }); }

    setLoading(false);
  }

  let idPannello = localStorage.getItem("idPannello");
  if(!idPannello) {
  return (
    <>

      <Grid container columns={{ xs: 12, sm: 12, md: 12 }}>
        <Grid xs={12} sm={12} md={12} lg={12}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className='boxSxLogin'>

            <div className='formContainer'>

              {/*               <img className='teletab-logo' src={teletabberlogo}/>
 */}
              {/*<div className='login_title'>{Dictionary.Login.loginFor_title}</div>}m*/}
              <div className='login_title'>ATTIVAZIONE PANNELLO</div>

              {/*  <p className='login_subtitle'>{Dictionary.Login.loginForm_subtitle}</p>*/}

              <FormControl className="formControlPassword" sx={{ m: 1, width: '22rem' }} variant="standard">
                <InputLabel color='success' htmlFor="standard-adornment-password">Password</InputLabel>
                <Input
                  error={state.submitted && state.passwordErrMsg.length > 0}
                  id="standard-adornment-password"
                  type={state.showPassword ? 'text' : 'password'}
                  onKeyDown={(e) => { noSpace(e) }}
                  onChange={(e) => { dispatch({ type: LoginActions.PASSWORD_CHANGE, value: e.target.value }) }}
                  color="success"
                />

                <div className='err_campo'>
                  {state.submitted && state.passwordErrMsg}
                </div>
              </FormControl>

              <Button
                type='submit'
                sx={{ m: 2, }}
                className="btnpad buttonLogin" color={'success'} variant="contained">
                ATTIVA
              </Button>

              <LoadingBar width='5rem' show={loading} />
              <div className='err_accesso'>
                {state.loginfailed && <span>accesso non riuscito</span>}
              </div>
              <div className='bot-spacer'></div>
            </div>
          </form>
        </Grid>

      </Grid>
    </>
  ) 
} else { return <></>} 
}

export default React.memo(Login);