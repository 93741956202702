
import "../Components/AlertWindow.css";
import { useEffect } from "react";

type Props = {
    openWindow: string;
    setOpenWindow: Function;
};

const AlertWindow: React.FC<Props> = ({ openWindow, setOpenWindow }) => {


    useEffect(() => {
        const cardTimer = setInterval(AutoClose, 4000);

        return function stopTimer() {
            clearInterval(cardTimer);
        }
    }, [openWindow]);

    function AutoClose() {
        if (openWindow) setOpenWindow('');
    }

    return (
        <div className="bodyBackground" style={{ display: (openWindow) ? '' : 'none' }}>
            <div style={{ display: openWindow? '' : 'none' }} className="alertBody">
                {openWindow}
            </div>
        </div>
    );
}

export default AlertWindow;
