import { Box, Typography, Paper, Grid, Divider, } from '@mui/material';
import "../../Pages/PanelHome/PanelHome.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCalendarDays, faGear, faStopCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import BoardPanel from '../../Components/BoardPanel';
import PanelService from '../../Services/PanelService';
import { PanelRoomData } from '../../Models/PanelRoomData';
import { ImpostazioniGenerali } from '../../Models/ImpostazioniGenerali';
import Keypad from '../../Components/Keypad';
import TimePad from '../../Components/TimePad';
import { SignageBook } from '../../Models/SignageBook';
import { CheckIn } from '../../Models/CheckIn';
import { ConstructionOutlined } from '@mui/icons-material';
import AlertWindow from '../../Components/AlertWindow';
import { StatoSala } from '../../Services/Enums/StatoSala';
import { Prenotazione } from '../../Models/Prenotazione';
import { useNavigate } from 'react-router-dom';
import OutOfConnection from '../Login/OutOfConnection';


const PanelHome = () => {

  const idPannello = localStorage.getItem("idPannello") as unknown as number;
  const [timerSlideshow, setTimerSlideShow] = useState(5000); //valore in millisecondi dell'intervallo per lo slideshow, potremmo configurarlo in appsettings
  const [logoDxPath, setLogoDxPath] = useState("");
  const [logoSxPath, setLogoSxPath] = useState("");
  const [panelRoomData, setPanelRoomData] = useState(new PanelRoomData());
  const [settings, setSettings] = useState(new ImpostazioniGenerali());
  const [keyPad, setKeyPad] = useState(false);
  const [timePad, setTimePad] = useState(false);
  const [stopSlideShow, setStopSlideShow] = useState(false);
  const [checkType, setCheckType] = useState("");
  const [signage, setSignage] = useState<SignageBook[]>([]);
  const [patternSlides, setPatternSlides] = useState(["Sala", "NextEvents", "Sala", "NextEvents"])
  const [resetIndex, setResetIndex] = useState(false);
  const [pollingTimer, setPollingTimer] = useState(10000);
  const [instantConfig, setInstantConfig] = useState(true);
  const [timefromEventStart, setTimefromEventStart] = useState(0);
  const [secondsfromEventStart, setSecondsfromEventStart] = useState(0);
  const [endOfEvents, setEndOfEvents] = useState(false);
  const [goShow, setGoShow] = useState(false); 

  useEffect(() => {setTimeout(()=>{setGoShow(true)}, 500)},[]);

  async function fetchPanelRoomData(idPannello: number) {
    const res = await PanelService.GetPanelRoomData(idPannello);
    setPanelRoomData(res);
    fetchPanelSignage(res.id!);

    localStorage.setItem(STORED_CURRENTEVENT_ID, "" + res.eventoInCorso?.id);
    localStorage.setItem(STORED_ROOMSTATE, res.stato || "");
  }

  async function fetchSettings() {
    const res = await PanelService.GetAllImpostazioni();
    setSettings(res[0]!)
  }

  function fetchTimeforCheckIn() {
    if (panelRoomData.eventoInCorso) {
      let now = new Date();
      let timeFrom = new Date(panelRoomData.eventoInCorso.dataInizio!);
      /*  let diff = (timeFrom.getTime() - now.getTime() )/ 60000;
       var minFromStart = Math.abs(Math.round(diff));
       setTimefromEventStart(minFromStart);  */

      let secDiff = ((timeFrom.getTime() - now.getTime()) / 1000);
      let seconds = secDiff % 60;
      let roundedSeconds = Math.round(seconds) * -1;
      setSecondsfromEventStart(roundedSeconds);

      let minutes = Math.round(secDiff / 60);
      var minFromStart = Math.abs(Math.round(minutes));
      setTimefromEventStart(minFromStart);
    }

  }

  async function fetchPanelConfig() {
    const res = await PanelService.GetPanelConfig();
    
    setLogoDxPath(res.logoDxPath!)
    setLogoSxPath(res.logoSxPath!);
    setPollingTimer(res.pollingInterval!);
    setInstantConfig(res.instantBooking!);
  }

  async function fetchPanelSignage(idSala: number) {

    const res = await PanelService.GetPanelSignage(idSala);
    setSignage(res);

    let patternWithSpot = ["Sala", "NextEvents", "Spot"];
    const repeatedArray = [].concat(...Array(res.length as number).fill(patternWithSpot));
    if (repeatedArray.length > 0) setPatternSlides(repeatedArray);
  }

  //carica PanelConfig all'avvio, PanelRoomData e Settings
  useEffect(() => {
    fetchPanelRoomData(idPannello);
    fetchPanelConfig();
    fetchSettings();
  }, []);


  const oraData = function fetchData() {
    const today = new Date();
    const _oraData: string = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
    return _oraData;
  }
  const [time, setTime] = useState(oraData);


  useEffect(() => { setTime(oraData) }, [oraData]);

  useEffect(()=>{
    if(panelRoomData.prossimiEventi?.length==0)
    {
      setEndOfEvents(true);}
      else{
        setEndOfEvents(false);
      }
  },[panelRoomData])

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date: Date) {
    return (
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes())
      ].join(':')
    );
  }

  function openKeyPad(checkType: string) {
    if (checkType === "In") {
      setCheckType("In");
    }
    else {
      setCheckType("Out");
    }
    setStopSlideShow(true);
    setKeyPad(true);
  }

  function checkSala() {
    setResetIndex(true);
    setTimeout(() =>
      setResetIndex(false), (panelRoomData.tSpot! * 1000)
    );
  }

  function closeKeyPad() {
    
    setTimeout(() =>
    setStopSlideShow(false), (panelRoomData.tStato!*1000) / 2
    )
   
    setKeyPad(false)
    setResetIndex(true);
    setTimeout(() =>
      setResetIndex(false), timerSlideshow
    );
  }
  function openTimePad() {
    setStopSlideShow(true);
    setTimePad(true);
  }

  function closeTimePad() {

    setTimeout(() =>
    setStopSlideShow(false), (panelRoomData.tStato!*1000) / 2 
    )
    setTimePad(false);
    setResetIndex(true);
    setTimeout(() =>
      setResetIndex(false), timerSlideshow
    );
  }


  //-----------------------NFC LOGIN---------------------------------

  const STORED_CARD_ID = "BsLiteCardId";
  const STORED_CURRENTEVENT_ID = "BsLiteCurrentEventId";
  const STORED_ROOMSTATE = "BsliteRoomState";
  const [checking, setChecking] = useState(false);
  const [showAlert, setShowAlert] = useState('');

  //avvia timer a caricamento pagina
  useEffect(() => {
    if (checking) return;
    setChecking(true);
    
    const cardTimer = setInterval(CheckStoredCard, 2000);

    return function stopTimer() {
      setChecking(false);
      clearInterval(cardTimer);
    }
  }, []);

  async function CheckStoredCard() {

    //raccoglie dati da localstorage

    let eventId = parseInt(localStorage.getItem(STORED_CURRENTEVENT_ID) || '0');
    let cardId = localStorage.getItem(STORED_CARD_ID);
    let roomState = localStorage.getItem(STORED_ROOMSTATE);
    localStorage.removeItem(STORED_CARD_ID);


    //se applicato tesserino su sala libera
    if (cardId && !eventId) {
      setShowAlert("impossibile accedere: nessun evento in corso");
      return
    }

    //se rilevato tesserino ed evento in corso
    let allowCheckIn = (cardId && eventId) ? true : false;

    if (allowCheckIn) {
      let result: any = null;

     
      let checkin: CheckIn = { IdPrenotazione: eventId, AccessCode: undefined, IdBadge: cardId || undefined };

      
      if (roomState === StatoSala.riservata) {
        result = await PanelService.CheckInNFC(checkin);
      } else {
        result = await PanelService.CheckOutNFC(checkin);
      }

      setShowAlert(result.msg);

      //if response == ok 
      fetchPanelRoomData(idPannello);
    }

  }
  //------------------------------------------------------------------
if(panelRoomData.stato !== undefined && goShow) {
  return (<>
  
    
   {/*  <h3>
      <input type='text' id='NFCID' />
      <button onClick={() => {
        let id = (document.getElementById("NFCID") as HTMLInputElement).value;
        localStorage.setItem(STORED_CARD_ID, id);
      }}>SET NFC</button>
    </h3> */}

    {/*alert feedback CheckIn-Checkout Nfc*/}
    <AlertWindow openWindow={showAlert} setOpenWindow={setShowAlert}></AlertWindow>

    { keyPad && <Keypad checkType={checkType} closeKeyPad={closeKeyPad} accessCode={panelRoomData.eventoInCorso?.accessCode} idPrenotazione={panelRoomData.eventoInCorso?.id} fetchRoomData={() => fetchPanelRoomData(idPannello)}></Keypad>}
    { timePad && <TimePad closeTimePad={closeTimePad} passo={10} idSala={panelRoomData.id!}
      fetchRoomData={() => fetchPanelRoomData(idPannello)} dataInizioNextEvent={panelRoomData.prossimiEventi?.length ? panelRoomData.prossimiEventi[0].dataInizio : null}></TimePad>}

    {(settings.checkInTimer && panelRoomData) &&
      <BoardPanel  _statoSala={panelRoomData.stato === StatoSala.occupata ? StatoSala.occupata : panelRoomData.stato === StatoSala.riservata? StatoSala.riservata : panelRoomData.stato === StatoSala.libera? StatoSala.libera : StatoSala.libera} 
         /*_statoSala={panelRoomData.eventoInCorso == null ? StatoSala.libera
          : (panelRoomData.eventoInCorso != null && panelRoomData.eventoInCorso.uniqueId == "InstantBooking") ? StatoSala.occupata
            : (panelRoomData.eventoInCorso != null && panelRoomData.eventoInCorso.uniqueId != "InstantBooking") ? StatoSala.riservata
              : panelRoomData.stato === StatoSala.occupata ? StatoSala.occupata : StatoSala.libera} */
        titoloMeeting={panelRoomData.eventoInCorso?.titolo}
        nextEvent={panelRoomData.prossimiEventi?.length ? panelRoomData.prossimiEventi[0].titolo : ""}
        inizioMeeting={panelRoomData.eventoInCorso?.dataInizio as unknown as string}
        fineMeeting={panelRoomData.eventoInCorso?.dataFine as unknown as string}
        referenteMeeting={panelRoomData.eventoInCorso?.nomeOrganizzatore}
        timerSlideshow={timerSlideshow}
        nextRoomEvents={panelRoomData.prossimiEventi}
        logoSxPath={logoSxPath}
        logoDxPath={logoDxPath}
        nomeSala={panelRoomData.nome!}
        fetchRoomData={() => fetchPanelRoomData(idPannello)}
        pollingTimer={pollingTimer}
        instantBooking={panelRoomData.instantBooking as boolean}
        checkInTimer={settings.checkInTimer as number}
        openKeyPad={openKeyPad}
        openTimePad={openTimePad}
        stopSlideShow={stopSlideShow}
        spotList={signage}
        patternSlides={patternSlides}
        resetIndex={resetIndex}
        tElenco={panelRoomData.tElenco! * 1000}
        tSpot={panelRoomData.tSpot! * 1000}
        tStato={panelRoomData.tStato! * 1000}
        token={panelRoomData.eventoInCorso?.accessCode ? panelRoomData.eventoInCorso.accessCode : null}
        instantConfig={instantConfig}
        timefromEventStart={timefromEventStart}
        fetchTimeforCheckIn={fetchTimeforCheckIn}
        secondsfromEventStart={secondsfromEventStart}
        checkSala={checkSala}
        endOfEvents={endOfEvents}
        checkInEnabled={panelRoomData.checkIn as boolean}
        uniqueId = {panelRoomData.eventoInCorso?.uniqueId}
        checkedIn = {panelRoomData.eventoInCorso?.checkedIn as boolean}
      />}

  </>
  )}
  else if(panelRoomData.stato === undefined && goShow) {
    return <OutOfConnection isConnected = {!panelRoomData.stato === undefined}/>
  } else {
    return <></>
  }
}
export default PanelHome;
