import { Box, Button, TextField } from "@mui/material";
import "../Components/TimePad.css";
import { useEffect, useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PanelService from "../Services/PanelService";

type Props = {
    closeTimePad: Function;
    passo: number;
    idSala: number;
    fetchRoomData: Function;
    dataInizioNextEvent?: Date | null;
};

const Timepad: React.FC<Props> = ({ closeTimePad, passo, idSala, fetchRoomData, dataInizioNextEvent }) => {

    //stop autopolling mentre ho aperto il timePad

    var newDate = new Date();
    newDate.setMinutes(newDate.getMinutes() + passo);

    const [timer, setTimer] = useState(15000); //tempo di default da attendere prima dell'autoclose del pad
    const [fineEvento, setFineEvento] = useState("fineEvento");
    const [invalidOrario, setInvalidOrario] = useState(false);
    const [orarioFine, setOrarioFine] = useState(new Date());
    const [ore, setOre] = useState(orarioFine.getHours());
    const [minuti, setMinuti] = useState(orarioFine.getMinutes());
    const [isOrarioModified, setIsOrarioModified] = useState(false);
    const [dataModificata, setDataModificata] = useState(newDate);
    const [titoloPrenotazione, setTitoloPrenotazione] = useState("");
    const [showTimeWall, setShowTimeWall] = useState(false);
    const [showTimeWallNegative, setShowTimeWallNegative] = useState(false);

    async function handleSubmit(orarioFine: Date) {
        reset();
        setInvalidOrario(false);

        let _orarioFine = new Date(orarioFine);
        let inizioNext = new Date(dataInizioNextEvent!);
        let dateInvalid: boolean = false;
        if (dataInizioNextEvent) dateInvalid = (_orarioFine >= inizioNext);

        if (orarioFine < new Date() || dateInvalid === true) {
            setFineEvento("fineEventoAnimated");
            setInvalidOrario(true);
            setTimeout(() => setFineEvento("fineEvento"), 500);
        } else {

            let result = await PanelService.Instant(orarioFine, idSala, titoloPrenotazione); //<==========QUI INS.NOME EVENTO
            if (result == "OK") { setTimeout(() => { fetchRoomData()}, 500); }
            closeTimePad();
        }
       // fetchRoomData(); //lettura dati

    }

    function subTime() {
        reset();
        setShowTimeWall(false);
        setShowTimeWallNegative(false);

        setIsOrarioModified(true);
        setInvalidOrario(false);
        let dataModificata = orarioFine;
        let check = new Date(dataModificata);
        check.setMinutes(check.getMinutes() - passo);
        if (check > new Date()) { 
        dataModificata.setMinutes(dataModificata.getMinutes() - passo);
        setDataModificata(dataModificata);
        } else {
            setShowTimeWallNegative(true);
        }
    }
    function addTime() {
        reset();
        setShowTimeWall(false);
        setShowTimeWallNegative(false);

        setIsOrarioModified(true);
        setInvalidOrario(false);
        let dataModificata = orarioFine; 
        let check = new Date(dataModificata);
        check.setMinutes(check.getMinutes() + passo);
       if ((dataInizioNextEvent!=null && (check < new Date(dataInizioNextEvent!)))||(dataInizioNextEvent == null)) { 
        dataModificata.setMinutes(dataModificata.getMinutes() + passo);
        setDataModificata(dataModificata);
        } else {
            setShowTimeWall(true);

    }}

    useEffect(() => { setOrarioFine(dataModificata); setIsOrarioModified(false); }, [isOrarioModified]);

    useEffect(() => {

        const timerAutoClose = setInterval(tick, 1000);
        return function cleanup() {
            clearInterval(timerAutoClose);
        };
    }, [timer]);

    const tick = () => {

        if (timer === 0)
            closeTimePad()
        else {
            setTimer(timer - 1000);
        }
    };

    const reset = () => setTimer(15000);

    function handle_change() {
        let titoloPrenotazione = (document.getElementById("titolo") as HTMLInputElement).value || "";
        setTitoloPrenotazione(titoloPrenotazione);
    }

    return (
        <div className="bodyBackground" onClick={() => closeTimePad()}>
            <Box className="boxTimePad" onClick={(e) => e.stopPropagation()}>

                <p className="titoloIB">Nome della Riunione</p>
                <Box className={fineEvento}>
                        <input id="titolo" autoFocus onChange={() => { reset(); handle_change() }}

                            onBlur={reset}
                            //nasconde tastiera a schermo su tablet
                            onKeyDown={(e)=>{
                                if (e.key == "Enter") (e.target as HTMLInputElement).blur() }}
                            
                                className="titoloTxt"
                            type="text" />
                </Box>
                <div className="titoloPad">Orario di Fine</div>
                <Box className={fineEvento}><div className="orarioFine">{orarioFine.getHours() < 10 ?
                    "0" + orarioFine.getHours().toString()
                    : orarioFine.getHours().toString()}
                    :
                    {orarioFine.getMinutes() < 10 ?
                        "0" + orarioFine.getMinutes().toString()
                        : orarioFine.getMinutes().toString()}</div>
                </Box>
                <Box className="boxControls">

                    <div className="controlMinus" onClick={subTime}><FontAwesomeIcon icon={faMinus} className="iconPlus" /></div>
                    <div className="controlSpacer"></div>
                    <div className="controlPlus" onClick={addTime}><FontAwesomeIcon icon={faPlus} className="iconMinus" /></div>

                </Box>
                <Button className="btn_submit" variant="contained" color="error"
                    onClick={() => handleSubmit(orarioFine)}>
                    Conferma
                </Button>
                <Button className="btn_back" variant="contained" color="error"
                    onClick={() => closeTimePad()}>
                    Annulla
                </Button>
                <p className="orarioNotValid" hidden={!showTimeWallNegative}>Impossibile prenotare prima di questo orario</p>
                <p className="orarioNotValid" hidden={!invalidOrario}>Impossibile prenotare prima di questo orario</p>
                <p className="orarioNotValid" hidden={!showTimeWall}>Impossibile prenotare oltre questo orario</p>

            </Box>

        </div>


    );
}

export default Timepad;
