import { useState } from "react";
import "../Components/Keypad.css";

type Props = {
    digit: number;
    addDigit: Function;
};

const DigitKey: React.FC<Props> = ({ digit, addDigit }) => {

    return (
        <div  onClick={() => addDigit(digit)} className="number" ><div className={"numberStyle"}>{digit}</div></div>
    );
}

export default DigitKey;
