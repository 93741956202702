export enum Colors{
    RED = "#ff0000",
    GREEN = "#00ff00",
    BLUE = "#0000ff",
    YELLOW = "#ffff00",
    WHITE = "#ffffff",
    OFF = "#000000"
}


export class LedService{

    static setLed(color:string){
        this.LedRequest(color);
    }

    static LedRequest(hex : string) {

        var xhr = new XMLHttpRequest(),

            data = {
                id: 0,
                name: 'frame',
                brightness: 1.0,
                color: hex
            },
            data_string = '';
            
        hex = hex.replace("#", "");

        xhr.open('POST', "http://localhost:8080" + '/light?led=' + hex, true);
       
       xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
       

        xhr.onreadystatechange = function () {
            if (this.readyState == 4) {
                if (this.status != 200) {
                }
                else {
                    
                }
            }
        }
        xhr.send();
    }

}