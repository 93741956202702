export default class Dictionary {

    static readonly Routes = {
        landingPage_panel: "/panel",
        landingPage_home: "/home"  
    }

    static readonly Login = {
        loginForm_title: "EFFETTUA IL LOGIN",
        loginForm_subtitle: "Inserisci la password"
    }

}