import { Prenotazione } from "./Prenotazione";
import { SignageBook } from "./SignageBook";

export class PanelRoomData {
    id?: number;
    nome?: string;
    stato?: string;
    tElenco?: number;
    tSpot?: number;
    tStato?: number;
    instantBooking?: boolean;
    checkIn?: boolean;
    eventoInCorso?: Prenotazione;
    prossimiEventi?: Prenotazione[];
    eventiDomani?: Prenotazione[];



    constructor(id?: number, nome?: string, stato?: string, tElenco?: number, tSpot?: number,
        tStato?: number, instantBooking?: boolean, checkIn?: boolean, eventoInCorso?: Prenotazione,
        prossimiEventi?: Prenotazione[], eventiDomani?: Prenotazione[]) {

        this.id = id;
        this.nome = nome;
        this.stato = stato;
        this.tElenco = tElenco;
        this.tSpot = tSpot;
        this.tStato = tStato;
        this.instantBooking = instantBooking;
        this.checkIn = checkIn;
        this.eventoInCorso = eventoInCorso;
        this.prossimiEventi = prossimiEventi;
        this.eventiDomani = eventiDomani;
    }

    public static costruttoreEsteso(
        id: number,
        nome: string,
        stato: string,
        tElenco: number,
        tSpot: number,
        tStato: number,
        instantBooking: boolean,
        checkIn: boolean,
        eventoInCorso: Prenotazione,
        prossimiEventi: Prenotazione[],
        eventiDomani: Prenotazione[]) {

        let panelData = new PanelRoomData();

        if (id) panelData.id = id;
        if (nome) panelData.nome = nome;
        if (stato) panelData.stato = stato;
        if (tElenco) panelData.tElenco = tElenco;
        if (tSpot) panelData.tSpot = tSpot;
        if (tStato) panelData.tStato = tStato;
        if (instantBooking) panelData.instantBooking = instantBooking;
        if (checkIn) panelData.checkIn = checkIn;
        if (eventoInCorso) panelData.eventoInCorso = eventoInCorso;
        if (prossimiEventi) panelData.prossimiEventi = prossimiEventi;
        if (eventiDomani) panelData.eventiDomani = eventiDomani;
        
        return panelData;
    }

}