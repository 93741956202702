import axios from "axios";
import { Endpoints } from "../Config/Endpoints";
import { CheckIn } from "../Models/CheckIn";
import { Config } from "../Models/Config";
import { ImpostazioniGenerali } from "../Models/ImpostazioniGenerali";
import { PanelRoomData } from "../Models/PanelRoomData";
import { Pannello } from "../Models/Pannello";
import { SignageBook } from "../Models/SignageBook";
import { SessionManager } from "./SessionManager";
import { RequestConfig } from "./Utils/RequestConfig";


export default class PanelService {

    public static async GetPanelConfig(): Promise<Config> {

        let config = new Config();

        await axios.get(Endpoints.ImpostazioniGen.get_Get_Panel_Config, RequestConfig.queryparams())

            .then((response: any) => {

                config = response.data;

                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return config;
    }

    public static async ListaPannelli() {

        let lista: Array<Pannello> = [];

        await axios.get(Endpoints.Panel.get_Get_Lista_Pannelli, RequestConfig.queryparams())

            .then((response: any) => {

                lista = response.data;

                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return lista;
    }

    public static async GetAllImpostazioni() {

        let impostazioni: Array<ImpostazioniGenerali> = [];
        await axios.get(Endpoints.ImpostazioniGen.get_Get, RequestConfig.queryparams())

            .then((response: any) => {

                impostazioni = response.data;
                if (impostazioni[0].checkInTimer! <= 0) {
                    impostazioni[0].checkInTimer = 1
                }
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return impostazioni;
    }

    public static async GetPanelRoomData(idPannello: number): Promise<PanelRoomData> {

        let panelData = new PanelRoomData();

        await axios.post(Endpoints.Panel.post_Get_Panel_RoomData,idPannello, RequestConfig.queryparams())

            .then((response: any) => {

                panelData = response.data;
                SessionManager.RefreshSession();
                return response.data;

            }, (error: any) => {
                console.log(error);
            });

        return panelData;
    }

    public static async CheckIn(idPrenotazione: number, accessCode: string): Promise<CheckIn> {

        let checkIn = new CheckIn();

        await axios.post(Endpoints.Prenotazione.post_CheckIn, { idPrenotazione, accessCode }, RequestConfig.queryparams())

            .then((response: any) => {

                checkIn = response.data;

                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return checkIn;
    }

    public static async checkOut(idPrenotazione: number, accessCode: string): Promise<CheckIn> {

        let checkIn = new CheckIn();

        await axios.post(Endpoints.Prenotazione.post_CheckOut, { idPrenotazione, accessCode }, RequestConfig.queryparams())

            .then((response: any) => {

                checkIn = response.data;
                return response.data;

            }, (error: any) => {
                console.log(error);
            });
        return checkIn;
    }


    public static async Instant(end: Date, idSala: number, Titolo: string): Promise<string> {
       

        let result: string = "";

        return await axios.post(Endpoints.Prenotazione.post_Instant_Booking, { end, idSala, Titolo }, RequestConfig.queryparams())

            .then((response: any) => {

                result = "OK";

                return result;

            }, (error: any) => {
                console.log(error);

                return result;
            });
    }

    public static async GetPanelSignage(idSala: number): Promise<SignageBook[]> {

        let signage: Array<SignageBook> = [];
        await axios.post(Endpoints.Panel.post_Get_Panel_Signage + idSala, RequestConfig.queryparams())

            .then((response: any) => {
                signage = response.data;
                return response.data;
                
            }, (error: any) => {
                console.log(error);
            });
        return signage;
    }


    public static async CheckInNFC(checkInModel: CheckIn): Promise<string> {

        return await axios.post(Endpoints.Prenotazione.post_CheckIn, checkInModel, RequestConfig.queryparams())

            .then((response: any) => {

                return response.data;

            }, (error: any) => {
                return error;
            });
    }

    public static async CheckOutNFC(checkInModel: CheckIn): Promise<string> {

        return await axios.post(Endpoints.Prenotazione.post_CheckOut, checkInModel, RequestConfig.queryparams())

            .then((response: any) => {

                return response.data;

            }, (error: any) => {
                return error;
            });
    }
}