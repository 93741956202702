import Password from "../../Models/Password";
import AuthService from "../AuthService";
import { Expressions } from "../Enums/Expressions";
import PanelService from "../PanelService";
import { action_loginModel, action_string, ReducerAction } from "./ReducerAction";

export class LoginState {
    loginModel: LoginModel = new LoginModel();

    emailValid: boolean = false;
    passwordValid: boolean = false;

    emailErrMsg = 'campo obbligatorio';
    passwordErrMsg = 'campo obbligatorio';

    submitted: boolean = false;
    loginfailed: boolean = false;
    showPassword: boolean = false;
}

export class LoginModel {
    email: string = ''; //=========> andrà utenza GestorePannelli cablata
    password: Password = new Password('');

    public static validaEmail(mail: string): boolean {
        if (mail === null || mail === undefined || mail === '') return false;
        else return Expressions.EMAIL_FORMAT_REGEX.test(mail);
    }

}

export async function GetLoginModel(){
    let model:LoginModel = new LoginModel();
    model.email = await AuthService.GetPanelUser();
   
    return model;
}

export enum LoginActions {
    EMAIL_CHANGE = 'change_email',
    PASSWORD_CHANGE = 'change_password',
    SUBMIT = 'submit',
    LOGIN_FAIL = 'loginfail',
    LOAD_PANELUSER ="loadpaneluser",
}


export function LoginReducer(state: LoginState, action: ReducerAction): LoginState {
    let newstate = { ...state };

    switch (action.type) {

        case LoginActions.EMAIL_CHANGE: {
            state.loginfailed = false;
            newstate.loginModel.email = (action as action_string).value.trim();
            newstate.emailValid = LoginModel.validaEmail(newstate.loginModel.email);

            if (newstate.loginModel.email === '') newstate.emailErrMsg = 'campo obbligatorio';
            else newstate.emailErrMsg = (newstate.emailValid) ? '' : 'formato email non valido';
            break;
        }
        case LoginActions.PASSWORD_CHANGE: {
            state.loginfailed = false;
            newstate.loginModel.password.set((action as action_string).value.trim());
            newstate.passwordValid = (newstate.loginModel.password.Value().length > 0);
            newstate.passwordErrMsg = (newstate.loginModel.password.Value()) ? '' : 'campo obbligatorio';
            break;
        }

        case LoginActions.SUBMIT: {
            newstate.submitted = true;
            newstate.loginfailed = false;
            break;
        }

        case LoginActions.LOGIN_FAIL: {
            newstate.submitted = true;
            newstate.loginfailed = true;
            break;
        }
        case LoginActions.LOAD_PANELUSER: {
            newstate.loginModel = (action as action_loginModel).value;
            break;
        }
    }

    return newstate;
}