import { LinearProgress } from "@mui/material";

type LoadingBarProps = {
    width?: string
    show: boolean
    marginLeft?: string
}

const LoadingBar: React.FC<LoadingBarProps> = ({ width, show, marginLeft }) => {
    return <>
        {show ?
            <LinearProgress color="success" style={{ width: width, marginLeft: marginLeft }} />
            :
            <div style={{ height: '4px', width: width }}></div>
        }
    </>
}
export default LoadingBar;